import React from "react";
import { BTextField } from "./FormStyles";
import contactIll from "../images/keyboard2.jpg";
import emailjs from "emailjs-com";
import validator from "validator";
import Footer from "./Footer";
import FadeIn from 'react-fade-in';
class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    message: "",
    status: "-",
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  }
  checkForm = () => {
    if (
      (this.state.name !== "",
      this.state.email !== "",
      this.state.message !== "")
    ) {
      if (validator.isEmail(this.state.email)) {
        return true
      } else {
        this.setState({ status: "Please enter a valid email." });
        return false;
      }
    } else {
      this.setState({ status: "Please complete the form." });
      return false;
    }
  }
  setsucess = () => { 
    this.setState({status:"Sucess"})
  }
  submitForm = () => {
    if (this.checkForm()) {
      let templateParams = {
        from_name: this.state.name,
        from_email: this.state.email,
        message: this.state.message,
      };
      emailjs.send(
        "service_18ve2pi",
        "template_nvspak8",
        templateParams,
        "cgPgkRhDNcGrugk0Q"
        
      )  
      .then((response) =>{ 
        alert("Success!")
        console.log('SUCCESS!', response.status, response.text);
      },(error)=>{
        alert("Failed, please try again!")
        console.log('FAILED...', error);

      })
      this.resetForm();
    }
  }
  resetForm = () => {
    this.setState({
      name: "",
      email: "",
      message: "",
      status: "Thank you for your response.",
    });
  }
  render() {
    return (
      <div className="main"  style={{ overflowY: this.props.disableScroll ? "hidden" : "scroll", position: this.props.disableScroll ? "fixed" : "relative"}}>
        <div className="colWithMargin">
          <h4>
            CONTACT US
          </h4>
          <h1>
            Feel free to drop by or shoot us an email
          </h1>

          <div className="rowMH verticalPaddingS">
            <div className=" bi ">
              <img
                src={contactIll}
                alt="contact ill"
                className="imgShadow contactIll"
              />
            </div>
            <div className="bi">
              <BTextField
                id="name"
                label="Name"
                variant="outlined"
                style={{ width: "100%", color : "#FC605A"}}
                onChange={(e) => this.handleChange(e)}
                value={this.state.name}
              />
              <br />
              <BTextField
                id="email"
                label="Email"
                variant="outlined"
                style={{ width: "100%" , color : "#FC605A"}}
                onChange={(e) => this.handleChange(e)}
                value={this.state.email}
              />
              <br />  
              <BTextField
                label="Your Message"
                placeholder="Your Message"
                variant="outlined"
                style={{ width: "100%" , color : "#FC605A"}}
                id="message"
                onChange={(e) => this.handleChange(e)}
                minRows={4}
                multiline
                maxRows={6}
                value={this.state.message}
              />
              <i
                style={{ opacity: this.state.status !== "-" ? 1 : 0 }}
                className="contactStatus topTextSpace regMaroonText noMarginText"
              >
                {this.state.status}
              </i>
              <div className="contactButtonDiv">
                <button onClick={this.submitForm} className="brightRedButton">Send</button>
              </div>
            </div>
          </div>
          <div className="colWithMargin verticalPaddingM centerVertical">
            <br /> <br />
            <p className="textCenter">
              ​​We’re on the ground floor of the John Crerar Library (see map
              below)! When entering from the main entrance, take a right and
              enter the MADD center through the large glass wall. Head over to
              the west side of the MADD center to which you will find the CSIL
              Labs! The CSIL tutor station is located between CSIL Lab 3 and 4
              which is roughly at the center of the west wall of the MADD
              center. If you can't find us, ask a friendly MADD attendant in a
              blue lab coat!
            </p>
            <br />
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2974.7535830949623!2d-87.60504268442789!3d41.790523979229384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e293c21dac439%3A0xcf45cf3ba1a46e95!2sJohn%20Crerar%20Library!5e0!3m2!1sen!2sus!4v1627858037611!5m2!1sen!2sus"
              width="60%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="map"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
